import * as React from 'react'

import ReactDOM from 'react-dom'
import ReCAPTCHA from 'react-google-recaptcha'

export enum CaptchaSize {
  COMPACT = 'compact',
  NORMAL = 'normal',
  INVISIBLE = 'invisible',
}

interface CaptchaProps {
  siteKey: string
  onChange: (response: string) => void
  size?: CaptchaSize
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}

export type CaptchaInterface = {
  reset: () => void
  execute: () => void
}

export const Captcha = React.forwardRef<
  CaptchaInterface | undefined,
  CaptchaProps
>((props, ref) => {
  return typeof document !== 'undefined' && document.body
    ? ReactDOM.createPortal(
        <ReCAPTCHA
          ref={ref}
          size={props.size}
          onChange={props.onChange}
          sitekey={props.siteKey}
          isolated={true}
        />,
        props.containerRef?.current
          ? props.containerRef.current
          : document.body,
      )
    : null
})
