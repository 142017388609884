import * as React from 'react'

import { useSiteConfig } from '../ConfigurationLoader'
import { useExtensions } from '../EnterpriseContext'

import { Captcha, CaptchaInterface, CaptchaSize } from './Captcha'

export enum RateLimitBucket {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTRATION',
  FORGOTTEN_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  MARKETING_MATERIAL_SIGN_UP = 'MARKETING_MATERIAL_SIGN_UP',
  ADD_REVIEW = 'ADD_REVIEW',
  WAITLIST = 'WAITLIST',
  REFERRAL_EMAIL = 'REFERRAL_EMAIL',
}

const CAPTCHA_BYPASS_TYPE_VISIBLE = 'V2_VISIBLE'
const CAPTCHA_BYPASS_TYPE_INVISIBLE = 'V2_INVISIBLE'

const LIST_ATTACK_STRATEGY_VISIBLE = 'VISIBLE-RECAPTCHA'
const LIST_ATTACK_STRATEGY_INVISIBLE = 'INVISIBLE-RECAPTCHA'

const BYPASS_MAP = {
  [LIST_ATTACK_STRATEGY_VISIBLE]: CAPTCHA_BYPASS_TYPE_VISIBLE,
  [LIST_ATTACK_STRATEGY_INVISIBLE]: CAPTCHA_BYPASS_TYPE_INVISIBLE,
}

type Callback = (response?: string) => void

export const useCaptcha = (config: {
  bucket: RateLimitBucket
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const extensions = useExtensions()
  const siteConfig = useSiteConfig()

  const captchaRef = React.useRef<CaptchaInterface>()
  const callbackRef = React.useRef<Callback>()

  React.useEffect(() => {
    typeof window !== 'undefined' &&
      (window.recaptchaOptions = {
        useRecaptchaNet: siteConfig.enableRecaptchaNet ? true : false,
      })
  }, [])

  const captchaObject = React.useMemo(() => {
    const firingBucket = extensions?.rateLimitersFiring?.find(
      (rateLimiter) =>
        rateLimiter.rateLimitingBucket === config.bucket.toString(),
    )

    const enabledBypass =
      firingBucket && siteConfig.listAttackStrategy
        ? firingBucket?.captchaBypassAvailable?.find(
            (bypass) =>
              bypass.type === BYPASS_MAP[siteConfig.listAttackStrategy!],
          )
        : undefined

    const captcha = enabledBypass?.type ? (
      <Captcha
        containerRef={config.containerRef}
        size={
          enabledBypass?.type === CAPTCHA_BYPASS_TYPE_INVISIBLE
            ? CaptchaSize.INVISIBLE
            : undefined
        }
        siteKey={enabledBypass?.siteKey}
        onChange={(response) => {
          if (!response || !callbackRef.current) {
            return
          }

          callbackRef.current(response)
        }}
        ref={captchaRef}
      />
    ) : null

    return {
      captcha,
      type: enabledBypass?.type,
      submit: (callback: Callback) => {
        if (
          !enabledBypass?.type ||
          enabledBypass?.type === CAPTCHA_BYPASS_TYPE_VISIBLE
        ) {
          callback()
          return
        }

        callbackRef.current = callback

        captchaRef?.current?.reset && captchaRef.current.reset()
        captchaRef?.current?.execute && captchaRef.current.execute()
      },
    }
  }, [
    extensions?.rateLimitersFiring,
    config.bucket,
    siteConfig.listAttackStrategy,
    captchaRef,
    callbackRef,
  ])

  return captchaObject
}
