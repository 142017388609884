import * as React from 'react'

import { FormFieldProps, FormProps } from './utils'

const camelize = (str: string) => {
  return (
    str &&
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  )
}

const renderForm = (fields: FormFieldProps[], refs: {}) => {
  return fields.map((field) => {
    return <FieldWrapper key={field.fieldName} field={field} refs={refs} />
  })
}

const FieldWrapper = (props: { field: FormFieldProps; refs: {} }) => {
  const ref = React.useRef(null)
  props.refs[camelize(props.field.fieldName)] = ref
  return props.field.renderField(ref, props.field.fieldName)
}

const getErrorFields = (checkErrors: {}): string => {
  return Object.keys(checkErrors).reduce((errorFields, ref) => {
    if (checkErrors[ref] !== null && checkErrors[ref].current !== null) {
      return `${errorFields} ${checkErrors[ref].current.label},`
    }
    return errorFields
  }, '')
}

export const DynamicForm = (props: FormProps) => {
  const {
    id,
    fields,
    csrf,
    children,
    renderAnnouncer,
    dropdownForm,
    validateOnRender,
    onSubmit,
    onMinimize,
  } = props
  const refs = {}
  const [checkErrors, setErrors] = React.useState({})
  const [refsState, setRefsState] = React.useState({})
  const [errorsToAnnounce, setErrorsToAnnounce] = React.useState('')
  const previousValuesRef = React.useRef({})

  React.useEffect(() => {
    if (validateOnRender) {
      Object.keys(refs).forEach((ref) => {
        if (!refs[ref].current.validate()) {
          setRefsState(refs)
          setErrors(refs)
        }
      })
    }
  }, [validateOnRender, refs])

  React.useEffect(() => {
    Object.keys(checkErrors).length !== 0
      ? setErrorsToAnnounce(
          `${Object.keys(checkErrors).length} error${
            Object.keys(checkErrors).length === 1 ? '' : 's'
          } in the form: ${getErrorFields(checkErrors)}`,
        )
      : setErrorsToAnnounce('')
  }, [checkErrors])

  React.useEffect(() => {
    Object.keys(checkErrors).forEach((ref) => {
      if (refsState[ref] !== null && refsState[ref].current !== null) {
        checkErrors[ref].current.validate()
      }
    })

    Object.keys(checkErrors).some((ref) => {
      if (refsState[ref] !== null && refsState[ref].current !== null) {
        if (!checkErrors[ref].current.validate()) {
          refs[ref].current.focus && refs[ref].current.focus()
          !dropdownForm && window.scrollTo(0, refs[ref].current.offsetTop)
          return true
        }
      }
      return false
    })
  }, [checkErrors, dropdownForm, refs, refsState])

  React.useEffect(() => {
    const currentValues = Object.keys(refsState).reduce((acc, ref) => {
      if (refsState[ref] !== null && refsState[ref].current !== null) {
        acc[ref] = refsState[ref].current.value
      }
      return acc
    }, {})

    const valuesHaveChanged = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValuesRef.current[key],
    )

    if (!valuesHaveChanged) return

    if (Object.keys(refsState).length !== 0) {
      let isValid = true
      const errorsToSet = Object.keys(refsState).reduce((errors, ref) => {
        if (refsState[ref] !== null && refsState[ref].current !== null) {
          if (!refsState[ref].current.validate()) {
            isValid = false
            errors[ref] = refsState[ref]
          }
        }
        return errors
      }, {})

      setErrors(errorsToSet)
      const values = {}
      Object.keys(refsState).forEach((ref) => {
        if (refsState[ref] !== null && refsState[ref].current !== null) {
          values[ref] =
            refsState[ref].current.value === 'true' ||
            refsState[ref].current.value === 'false'
              ? refsState[ref].current.value === 'true'
              : refsState[ref].current.value
        }
      })

      if (isValid) {
        onSubmit(values)
      } else if (onMinimize) {
        onSubmit(values, true)
      }

      previousValuesRef.current = currentValues
    }
  }, [refsState, onSubmit, onMinimize])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setRefsState(refs)
  }
  const formFields = renderForm(fields, refs)

  return (
    <form noValidate method="POST" onSubmit={handleSubmit} id={id}>
      {csrf && <input type="hidden" name="_csrf" value={csrf} />}
      <React.Fragment>
        {renderAnnouncer && renderAnnouncer('assertive', errorsToAnnounce)}
        {formFields}
        {children}
      </React.Fragment>
    </form>
  )
}
